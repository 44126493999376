import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { MenuItem, Select, TextField } from "@mui/material";
import { CustomButton } from "./customButton";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    "& .MuiButtonBase-root": {
      width: "100%",
      borderRadius: "100px",
      background: "#006298",
      fontSize: 14,
      fontWeight: 600,
      textTranform: "none",
    },
    "& .MuiInputBase-root": {
      marginBottom: 16,
      height: 44,
    },
    "& .MuiInputBase-input": {
      padding: "16px 12px",
    },
  },
  textBlock: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
  },
}));

export default function AdaptiveSelectCard({ content, handleClick }) {
  const classes = useStyles();

  const [textFieldValue, setTextFieldValue] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  const getDefaultDropdownValue = (body) => {
    const element = body.find((element) => element.type == "Input.ChoiceSet");
    return element ? { [element.id]: element.choices[0].value } : {};
  };

  const [dropdownObject, setDropdownObject] = useState(
    getDefaultDropdownValue(content?.body)
  );

  const confirmDropdown = (element) => {
    if (dropdownObject) {
      const newObject = { ...element.data, ...dropdownObject };
      element.data = newObject;
    }
    if (textFieldValue) {
      element.data["textField"] = textFieldValue;
    }
    handleClick(element);
    setConfirmed(true);
  };

  const handleSelectChange = (event, id) => {
    const dropdownObject = {};
    dropdownObject[id] = event.target.value;
    setDropdownObject(dropdownObject);
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  return (
    <div className={classes.root}>
      {content.body.map((element) => {
        switch (element.type) {
          case "TextBlock":
            return <div className={classes.textBlock}>{element.text}</div>;

          case "Input.ChoiceSet":
            return (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{
                  width: "100%",
                  height: "44px",
                }}
                value={dropdownObject[element.id]}
                onChange={(event) => handleSelectChange(event, element.id)}
                defaultValue={dropdownObject[element.id]}
              >
                {element.choices.map((choice) => {
                  return (
                    <MenuItem value={choice.value}>{choice.title}</MenuItem>
                  );
                })}
              </Select>
            );
          case "Input.Text":
            return (
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={handleTextFieldChange}
              />
            );
        }
      })}
      {!confirmed
        ? content?.actions.map((element) => {
            return (
              <CustomButton
                onClick={() => confirmDropdown(element)}
                variant="contained"
                element={element}
              ></CustomButton>
            );
          })
        : null}
    </div>
  );
}

AdaptiveSelectCard.propTypes = {
  content: PropTypes.object,
};
