import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class CalendarDay extends React.Component {
  get classes() {
    return classNames('body-cell', {
      'past-day'      : this.props.isPastDay,
      'prev-month-day': this.props.isPrevMonthDay,
      'current-day'   : this.props.isCurrentDay,
      'selected'      : this.props.isSelectedDate,
      'has-events'    : this.props.hasEvents,
      'next-month-day': this.props.isNextMonthDay
    });
  }

  handleClick(e) {
    e.preventDefault();

    if (this.props.hasEvents) {
      this.props.onDateSelect(this.props.date);
    }
  }

  render() {
    return (
      <div className={ this.classes }>
        <span role="button" tabIndex="-1" onClick={(e) => this.handleClick(e, this.props.onDateSelect)} className="date-number">{ this.props.day }</span>
      </div>
    )
  }
}

CalendarDay.propTypes = {
  onDateSelect: PropTypes.func.isRequired,
  day: PropTypes.number.isRequired,
  isPastDay: PropTypes.bool.isRequired,
  isPrevMonthDay: PropTypes.bool.isRequired,
  isCurrentDay: PropTypes.bool.isRequired,
  isSelectedDate: PropTypes.bool.isRequired,
  hasEvents: PropTypes.bool.isRequired,
  isNextMonthDay: PropTypes.bool.isRequired,
  date: PropTypes.object.isRequired
};
