import React from "react";
import { makeStyles } from "@mui/styles";
import { CustomButton } from "./customButton";

const useStyles = makeStyles(() => ({
  tierTwoButton: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #DAE2EA !important",
    },
  },
  list_button: {
    height: "52px !important",
  },
  single_button: {
    borderRadius: "8px 8px 8px 0px !important",
  },
  first_button: {
    borderRadius: "8px 8px 0px 0px !important",
  },
  middle_button: {
    borderRadius: "0px !important",
  },
  last_button: {
    borderRadius: "0px 0px 8px 0px !important",
  },
}));

export function TierTwoButton({ actions, handleChipClick }) {
  const classes = useStyles();

  const onClick = (action) => {
    if (action.url) {
      window?.open(action.url);
      action.data = {
        linkSelected: `${action.title}`,
      };
      emitToMobile(action);
    }
    handleChipClick(action);
  };

  const emitToMobile = (data) => {
    data = JSON.stringify(data);
    window.android?.openURL(data);
    window.webkit?.messageHandlers?.openURL?.postMessage(data);
  };

  const getBorderRadius = (actions, index) => {
    const singleButton = actions.length === 1;
    const firstButton = index === 0;
    const last_button = index === actions.length - 1;
    let borderRadius = classes.middle_button;
    if (singleButton) {
      borderRadius = classes.single_button;
    } else if (firstButton) {
      borderRadius = classes.first_button;
    } else if (last_button) {
      borderRadius = classes.last_button;
    }

    return `${borderRadius} ${classes.list_button}`;
  };

  return (
    <div>
      {actions?.map((action, index) => (
        <div className={classes.tierTwoButton}>
          <CustomButton
            onClick={() => onClick(action)}
            element={action}
            className={getBorderRadius(actions, index)}
          />
        </div>
      ))}
    </div>
  );
}
