import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import CalendarRow from "./CalendarRow";
import CalendarDay from "./CalendarDay";

export default class Body extends Component {
  static propTypes = {
    currentDate: PropTypes.string.isRequired,
    selectedDate: PropTypes.string.isRequired,
    onDateSelect: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
  };

  getCurrentDateMoment() {
    return moment(this.props.currentDate).startOf("day");
  }

  getDayMoment(weekDay) {
    return moment(this.props.currentDate).date(weekDay).startOf("week");
  }

  getRow(week) {
    const weekDay = week * 7 + 1;
    const currentMoment = this.getCurrentDateMoment();
    const dayMoment = this.getDayMoment(weekDay);
    const days = [];

    for (let day = 0; day < 7; day += 1) {
      let events = 0;

      if (dayMoment.isSame(currentMoment, "month")) {
        events = this.hasSlots(dayMoment);
      }

      const dayProps = {
        day: dayMoment.date(),
        isPrevMonthDay: dayMoment.isBefore(currentMoment, "month"),
        isNextMonthDay: dayMoment.isAfter(currentMoment, "month"),
        isCurrentDay: dayMoment.isSame(moment(), "day"),
        isPastDay: dayMoment.isBefore(moment(), "day"),
        isSelectedDate: dayMoment.isSame(
          moment(this.props.selectedDate).startOf("day")
        ),
        onDateSelect: this.props.onDateSelect,
        date: dayMoment.toDate(),
        hasEvents: events !== 0,
      };

      dayMoment.add(1, "days");
      days.push(<CalendarDay key={dayMoment.format("X")} {...dayProps} />);
    }

    return <CalendarRow key={week}>{days}</CalendarRow>;
  }

  hasSlots(dayMoment) {
    return this.props.events[dayMoment.date()]?.length || 0;
  }

  render() {
    const monthMoment = moment(this.props.currentDate).startOf("month");
    const monthLength = monthMoment.clone().endOf("month").date();
    const offset = monthMoment.day();
    const weeks = Math.ceil((monthLength + offset) / 7);
    const rows = [];

    for (let i = 0; i < weeks; i += 1) {
      rows.push(this.getRow(i));
    }

    return <div className="body">{rows}</div>;
  }
}
