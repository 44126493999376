import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Controls from "./components/Controls";
import Header from "./components/Header";
import Body from "./components/Body";

export default class Calendar extends React.Component {
  static propTypes = {
    defaultDate: PropTypes.string.isRequired,
    selectedDate: PropTypes.string,
    onDateSelect: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    minDate: PropTypes.string,
    onMonthChange: PropTypes.func,
    currentDate: PropTypes.string.isRequired,
  };

  _displayPrevMonth() {
    const newDate = moment(this.props.selectedDate)
      .subtract(1, "month")
      .toDate();

    this.setState({ currentDate: newDate });

    if (this.props.onMonthChange) {
      this.props.onMonthChange(newDate);
    }
  }

  _displayNextMonth() {
    const newDate = moment(this.props.selectedDate).add(1, "month").toDate();
    this.setState({ currentDate: newDate });

    if (this.props.onMonthChange) {
      this.props.onMonthChange(newDate);
    }
  }

  render() {
    return (
      <div className="hw-calendar">
        <Controls
          currentDate={this.props.selectedDate}
          minDate={this.props.minDate}
          onPrevMonthClick={() => this._displayPrevMonth()}
          onNextMonthClick={() => this._displayNextMonth()}
        />
        <Header />
        <Body
          currentDate={this.props.selectedDate}
          selectedDate={this.props.selectedDate || this.props.currentDate}
          onDateSelect={this.props.onDateSelect}
          events={this.props.events}
        />
      </div>
    );
  }
}
