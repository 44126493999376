export const EVENTS = {
  CLOSE_CHAT_WINDOW: "close_chat_window",
  AGENT_JOINED: "agentJoined",
  AGENT_LEFT: "agentLeft",
  CONVERSATION_STARTED: "conversationStarted",
  USER_INTERACTED: "userInteracted",
  CLIENT_INFO: "ClientInfo",
};

export const ACTIONS = {
  INCOMMING_ACTIVITY: "DIRECT_LINE/INCOMING_ACTIVITY",
  POST_ACTIVITY: "DIRECT_LINE/POST_ACTIVITY",
  CONNECTION_FULLFILLED: "DIRECT_LINE/CONNECT_FULFILLED",
};
