import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default class Controls extends React.Component {
  static propTypes = {
    currentDate: PropTypes.string.isRequired,
    onPrevMonthClick: PropTypes.func.isRequired,
    onNextMonthClick: PropTypes.func.isRequired,
    minDate: PropTypes.string,
  };

  render() {
    const displayMoment = moment(this.props.currentDate);
    const showBackButton =
      this.props.minDate === undefined ||
      moment(this.props.minDate).isBefore(displayMoment, "month");
    const maxDate = moment().add(90, "days").format("YYYY-MM-DD");
    const showNextbutton = moment(maxDate).isAfter(displayMoment, "month");
    return (
      <div className="controls">
        <FontAwesomeIcon
          className={!showBackButton ? "disabled" : "icon"}
          onClick={this.props.onPrevMonthClick}
          icon={faChevronLeft}
          size="1x"
          color="black"
        />

        <h2 className="month-title">
          <label> {displayMoment.format("MMMM YYYY")} </label>
        </h2>
        <FontAwesomeIcon
          className={!showNextbutton ? "disabled" : "icon"}
          onClick={this.props.onNextMonthClick}
          icon={faChevronRight}
          size="1x"
          color="black"
        />
      </div>
    );
  }
}
