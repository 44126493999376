import React from "react";

const Header = () => (
  <div className="header day-labels">
    <div className="day-label header-cell day-sunday">S</div>
    <div className="day-label header-cell day-monday">M</div>
    <div className="day-label header-cell day-tuesday">T</div>
    <div className="day-label header-cell day-wednesday">W</div>
    <div className="day-label header-cell day-thursday">T</div>
    <div className="day-label header-cell day-friday">F</div>
    <div className="day-label header-cell day-saturday">S</div>
  </div>
);

export default Header;
