import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import Calendar from "./Scheduling/Calendar";
import moment from "moment";
import { CustomButton } from "./customButton";

const SchedulingCard = ({ slots, handleClick }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const date = moment(Date.now());
  const allSlots = allAppointments();
  const [selectedMonthSlots, setSelectedMonthSlots] = useState(
    getSelectedMonthSlots(date)
  );
  const [selectedDate, setSelectedDate] = useState(selectedDay());
  const nextAvailable = selectedDay();
  const nextAvailableOffice = selectedAppointments(
    selectedMonthSlots.officeAppts,
    selectedDate.nextOffice
  );
  function allAppointments() {
    const monthlySlots = {};
    const availabilitySlots = slots || [];
    availabilitySlots.forEach((slot) => {
      const { appointmentDate, appointmentTime } = slot.data;
      slot.title = moment(appointmentTime, "HH:mm:ss").format("hh:mm A");
      if (!appointmentDate) return;
      const month = moment(appointmentDate).month() + 1;
      const date = moment(appointmentDate).date();

      if (!monthlySlots[month]) {
        monthlySlots[month] = {};
      }
      monthlySlots[month][date] = monthlySlots[month][date] || [];
      monthlySlots[month][date].push(slot);
      // Update nextAvailable for the specific reasonId and office
      if (!monthlySlots.nextAvailable) {
        monthlySlots.nextAvailable = moment(appointmentDate)
          .format("YYYY-MM-DD")
          .valueOf();
      }
    });

    return monthlySlots;
  }

  function getSelectedMonthSlots(date, calendarclicked = false) {
    const isSelectedDateWithin90Days = moment().diff(date, "days") > -90;
    const selectedMonth = moment(date, "YYYY-MM-DD").month() + 1;
    const officeAppts = allSlots[selectedMonth];
    const hasOfficeAppointments = hasAvailableAppointment(officeAppts);
    const finishedLoadingAndHaveNoAppointments =
      !hasOfficeAppointments && isSelectedDateWithin90Days && !calendarclicked;
    if (finishedLoadingAndHaveNoAppointments) {
      const nextMonthDate = moment(date).add(1, "month").format("YYYY-MM-DD");
      return getSelectedMonthSlots(nextMonthDate, calendarclicked);
    }

    return {
      officeAppts: officeAppts,
    };
  }
  function hasAvailableAppointment(arrayOfDaysWithAppointments = {}) {
    return Object.keys(arrayOfDaysWithAppointments).length > 0;
  }

  function selectedDay() {
    return {
      nextOffice: allSlots && allSlots?.nextAvailable,
    };
  }
  function selectedAppointments(appts, date) {
    const dateKey = moment(date).date();
    return appts ? appts[dateKey] ?? [] : [];
  }

  function getOfficeAppointments() {
    return selectedAppointments(
      selectedMonthSlots.officeAppts,
      selectedDate.nextOffice
    );
  }

  function TabPanel(props) {
    const { children, value, blockName, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  function _handleDateChange(date) {
    setSelectedDate({
      nextOffice: date,
      nextVideo: date,
    });
  }

  function _handleMonthChange(newDate) {
    const selectedMonthSlots = getSelectedMonthSlots(newDate, true);
    setSelectedMonthSlots(selectedMonthSlots);
    let firstAvailableOfficeapptKey;

    if (selectedMonthSlots.officeAppts) {
      firstAvailableOfficeapptKey = Object.keys(
        selectedMonthSlots.officeAppts
      )[0];
    }

    const firstAvailableOfficeapptDate = firstAvailableOfficeapptKey
      ? selectedMonthSlots.officeAppts[firstAvailableOfficeapptKey][0]?.data
          ?.appointmentDate
      : newDate;

    setSelectedDate({
      nextOffice: moment(firstAvailableOfficeapptDate)
        .format("YYYY-MM-DD")
        .valueOf(),
    });
  }

  const CustomTab = withStyles({
    root: {
      textTransform: "none !important",
      "& .MuiButtonBase-root-MuiTab-root": {},
    },
  })(Tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTimeSlots = ({ date, appointments }) => {
    return (
      <div className={classes.nextAvailable}>
        <div className={classes.tabHeader}>
          {moment(date).format("dddd, MMM D")}
        </div>
        <div className={classes.timeSlots}>
          {appointments.map((slot) => {
            return (
              <CustomButton
                variant="outlined"
                className={classes.contained}
                element={slot}
                onClick={handleClick}
              ></CustomButton>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="fullWidth"
        textColor="primary"
        className={classes.tabsHeader}
      >
        <CustomTab label="Next Available" {...a11yProps(0)} />
        <CustomTab label="View All Dates" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {getTimeSlots({
          date: nextAvailable.nextOffice,
          appointments: nextAvailableOffice,
        })}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={`calendar`}>
          <Calendar
            selectedDate={selectedDate.nextOffice}
            currentDate={date}
            onDateSelect={(date) => _handleDateChange(date)}
            onMonthChange={(date) => _handleMonthChange(date)}
            events={selectedMonthSlots.officeAppts ?? []}
            minDate={moment(Date.now()).format("YYYY-MM-DD")}
          />
        </div>
        {getTimeSlots({
          date: selectedDate.nextOffice,
          appointments: getOfficeAppointments(),
        })}
      </TabPanel>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "unset",
  },
  tabsHeader: {
    borderBottom: "1px solid #B9C5D1",
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#006298",
    },
    "& .MuiTab-root": {
      fontFamily: "Montserrat",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "20px",
      color: "#191C1C",
    },
  },
  cardContent: {
    padding: "24px",
  },
  nextAvailable: {
    padding: "16px 0 16px 16px",
    display: "flex",
    flexDirection: "column",
    gap: 16,

    "& .MuiButtonBase-root": {
      borderRadius: "8px",
      height: "32px",
      minWidth: "88px",
      maxWidth: "104px",
      padding: "6px 12px",
      color: "#454747",
      fontSize: 12,
      textTransform: "lowercase !important",
      "&:active": {
        background: "#075080",
        color: "white !important",
      },
    },
  },
  tabHeader: {
    fontSize: 14,
    fontWeight: 600,
  },
  timeSlots: {
    display: "flex",
    gap: 8,
    paddingBottom: 8,
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "8px",
    },

    "&::-webkit-scrollbar-thumb ": {
      backgroundColor: "#888",
      borderRadius: " 5px",
    },
  },
}));

export default SchedulingCard;
