export const getVariant = (text) => {
  switch (text) {
    case "Confirm":
    case "Confirm Details":
    case "Continue":
    case "Submit":
    case "Verify":
      return "contained";
    case "Resend":
    case "Skip":
      return "outlined";
    default:
      return "text";
  }
};

export const emitData = (data) => {
  window.parent?.postMessage(data, "*");
  //Mobile needs stringified data
  data = JSON.stringify(data);
  if (window.android) {
    window.android.botEvents(data);
  }
  window.webkit?.messageHandlers.botEvents?.postMessage(data);
};

export function getUserBrowserDetails() {
  const userAgent = navigator.userAgent;

  return {
    userAgent: userAgent,
  };
}
