import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { CustomButton } from "./customButton";
import { getVariant } from "../helpers/utils";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",

    "& .MuiInputBase-root": {
      marginBottom: 16,
      height: 44,
    },
    "& .MuiInputBase-input": {
      padding: "16px 12px",
    },
  },
  textBlock: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
  },
  actionButton: {
    marginBottom: "8px !important",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
}));

export function FormInputcard({ content, handleClick, isRequired }) {
  const classes = useStyles();
  const [formValues, setformValues] = useState({});
  const [disabled, setIsdisabled] = useState(isRequired);
  const [disableTextField, setDisableTextField] = useState(false);

  const confirmDropdown = (element) => {
    element.data = { ...element.data, ...formValues };
    setIsdisabled(true);
    setDisableTextField(true);
    handleClick(element);
  };

  const handleTextFieldChange = (element, event) => {
    setformValues((prevForm) => ({
      ...prevForm,
      [element.id]: event.target.value,
    }));
    if (event.target.value.length) {
      setIsdisabled(false);
    } else {
      setIsdisabled(isRequired);
    }
  };

  return (
    <div className={classes.root}>
      {content.body.map((element) => {
        return (
          <>
            <div className={classes.textBlock}>{element.label}</div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={(event) => handleTextFieldChange(element, event)}
              disabled={disableTextField}
            />
          </>
        );
      })}
      {content?.actions.map((element) => {
        return (
          <CustomButton
            onClick={() => confirmDropdown(element)}
            variant={getVariant(element.title)}
            element={element}
            className={classes.actionButton}
            disabled={disabled}
          ></CustomButton>
        );
      })}
    </div>
  );
}

FormInputcard.propTypes = {
  content: PropTypes.object,
};
